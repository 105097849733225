import {
  MetaSwiper,
  SafariSwiper,
  getPosition,
  is_touch_enabled,
} from "./meta-settings.js";

let basket = {};

function priceBlockLogic(priceBlocksArr, { amountCalc = null, discount = 0 }) {
  const cartArr = [];

  priceBlocksArr.forEach((elem, index) => {
    const priceBlock = elem;
    const elemPrice = priceBlock.querySelector(`.cart__price span`);
    const elemTotal = priceBlock.querySelector(`.cart__total span`);
    const nameProduct = priceBlock.querySelector(".cart__name")?.innerText;
    const elemHiddenCount = priceBlock.querySelector(".cart__hidden-count");

    const counterBlock = priceBlock.querySelector(`.counter-block`);
    const counterBtnPlus = counterBlock.querySelector(`.btn-counter-plus`);
    const counterBtnMinus = counterBlock.querySelector(`.btn-counter-minus`);
    const counterInput = counterBlock.querySelector(`.count-input`);
    const counterProduct = counterBlock.querySelector(`.product-count`);

    const maxQuantity = +priceBlock.getAttribute("data-max-quantity") || 1;

    // recalculate Cart and total
    function recalculateCart() {
      counterInput.value = item.quantity;
      if (counterProduct) {
        counterProduct.value = item.quantity;
      }

      if (elemTotal) {
        elemTotal.textContent = item.total().toFixed(2).toLocaleString("en-GB");
      }

      item.quantity === item.maxQuantity
        ? counterBtnPlus.classList.add("disabled")
        : counterBtnPlus.classList.remove("disabled");
      item.quantity === 1
        ? counterBtnMinus.classList.add("disabled")
        : counterBtnMinus.classList.remove("disabled");

      if (amountCalc) {
        amountCalc();
      }
    }

    let item = {
      id: priceBlock.getAttribute("data-id"),
      productId: priceBlock.getAttribute("data-product-id"),
      name: nameProduct,
      quantity:
        Number(counterInput.value) > maxQuantity
          ? maxQuantity
          : Number(counterInput.value) || 1,
      maxQuantity: maxQuantity || 1,
      price: +elemPrice.textContent,
      discount: Number(priceBlock.getAttribute("data-discount")) || discount,

      view: {
        totalView: function (total) {
          elemTotal.textContent = total.toFixed(2).toLocaleString("en-GB");
        },
      },

      total: function () {
        const subtotalWithoutDiscount = +(this.quantity * this.price).toFixed(
          2
        );
        const discountRate = (100 - this.discount) / 100;
        const total =
          Math.floor(subtotalWithoutDiscount * discountRate * 100) / 100;

        this.view.totalView(total);
        return total;
      },
    };

    cartArr.push(item);

    // Change price of block to LocaleString
    elemPrice.textContent = Number(elemPrice.textContent)
      .toFixed(2)
      .toLocaleString("en-GB");

    recalculateCart();

    // onclick Plus button
    counterBtnPlus.addEventListener(`click`, () => {
      item.quantity >= item.maxQuantity
        ? (item.quantity = item.maxQuantity)
        : (item.quantity += 1);
      recalculateCart();
    });

    // onclick Minus button
    counterBtnMinus.addEventListener(`click`, () => {
      item.quantity <= 1 ? (item.quantity = 1) : (item.quantity -= 1);
      recalculateCart();
    });

    // on input focus or blur
    counterInput.addEventListener("focus", () => {
      counterInput.value = "";
    });
    counterInput.addEventListener("blur", () => {
      counterInput.value = item.quantity;
    });
    counterInput.addEventListener("input", (e) => {
      counterInput.value = counterInput.value.replace(/[^\d.]/g, "");
      if (counterInput.value <= 0) {
        counterInput.value = 1;
      } else if (counterInput.value >= item.maxQuantity) {
        counterInput.value = item.maxQuantity;
      }
      item.quantity = +counterInput.value;
      recalculateCart();
    });

    // onclick Delete button
    const deleteBtn = priceBlock.querySelector(".cart__delete");
    deleteBtn?.addEventListener("click", function () {
      elem.remove();
      localStorage.removeItem(cartArr[index].id);
      cartArr.splice(index, 1);

      recalculateCart();
    });
  });

  return cartArr;
}
function amountCalc() {
  const amount = basket.amount();
  if (amount === 0) {
    basket.view.amount.innerHTML = "0 Items";
    basket.view.title.classList.add("my-6");
  } else {
    basket.view.amount.innerHTML = `${
      amount === 1 ? amount + " Item" : amount + " Items"
    }`;
    basket.view.title.classList.remove("my-6");
  }

  const subtotal = basket.subtotal();
  const total = basket.total();

  basket.view.shipping.innerHTML = `£${
    subtotal > 100
      ? "0.00"
      : Number(basket.shipping).toFixed(2).toLocaleString("en-GB")
  }`;
  basket.view.subtotal.innerHTML = `£${subtotal
    .toFixed(2)
    .toLocaleString("en-GB")}`;
  basket.view.VAT.innerText = `£${basket
    .VAT()
    .toFixed(2)
    .toLocaleString("en-GB")}`;
  basket.view.total.innerText = `£${
    subtotal === 0 ? "0.00" : total.toFixed(2).toLocaleString("en-GB")
  }`;
  basket.view.hiddenTotal.value = `${
    subtotal === 0 ? "0.00" : total.toFixed(2).toLocaleString("en-GB")
  }`;
}

window.startLoader = function startLoader(button = false) {
  if (!button) {
    document.getElementById("global-loader").classList.add("active");
  } else {
    button.classList.add("active");
  }
};

window.stopLoader = function stopLoader(buttonID = "global-loader") {
  // from back response "false", so need to make optional chaining
  buttonID = buttonID || "global-loader";
  document.getElementById(buttonID).classList.remove("active");
};

window.shopFunc = function shopFunc() {
  if (document.getElementsByClassName(`price-buy-block`)[0]) {
    const priceBlocksArr = [
      ...document.getElementsByClassName("price-buy-block"),
    ];

    priceBlockLogic(priceBlocksArr, {
      amountCalc: null,
      discount: 0
    });
  }
};

window.cartFunc = function cartFunc({ discount = 0 }) {
  if (
    document.getElementsByClassName(`price-buy-block`)[0] ||
    document.getElementsByClassName("cart")[0]
  ) {
    const priceBlocksArr = [
      ...document.getElementsByClassName("price-buy-block"),
    ];

    let cartArr = [];

    const totalSection = document.querySelector(".totals");

    if (totalSection) {
      const subtotal = totalSection.querySelector(".totals__subtotal");
      const shipping = totalSection.querySelector(".totals__shipping");
      const VAT = totalSection.querySelector(".totals__VAT");
      const total = totalSection.querySelector(".totals__total");
      const hiddenTotal = totalSection.querySelector(".hidden_total");
      const amount = document.querySelector(".cart__amount");
      const title = document.querySelector(".cart__title");

      basket = {
        status: true,
        view: {
          subtotal: subtotal,
          shipping: shipping,
          VAT: VAT,
          total: total,
          hiddenTotal: hiddenTotal,
          amount: amount,
          title: title,
        },
        // discount: Number(discount),
        shipping: +totalSection.getAttribute("data-shipping"),
        cart: priceBlockLogic(priceBlocksArr, {
          amountCalc: null,
          discount,
        }),
        amount: function () {
          return +this.cart
            .reduce((accumulator, object) => accumulator + object.quantity, 0)
            .toFixed(0);
        },
        subtotal: function () {
          const subtotal = +this.cart.reduce(
            (accumulator, object) => accumulator + object.total(),
            0
          );
          return subtotal;

          // const discountRate = (100 - this.discount) / 100;
          //
          // return Math.floor(subtotalWithoutDiscount * discountRate * 100) / 100;
        },
        VAT: function () {
          return (
            Math.floor((this.total() - (this.total() / 6) * 5) * 100) / 100
          );
        },
        total: function () {
          if (this.subtotal() < 100) {
            // with shipping
            return Math.floor((this.subtotal() + this.shipping) * 100) / 100;
          } else {
            // without shipping
            return Math.floor(this.subtotal() * 100) / 100;
          }
        },
      };
    }

    cartArr = priceBlockLogic(priceBlocksArr, {
      amountCalc: amountCalc,
    });

    if (basket.status) {
      amountCalc();
    }
  }
};

window.updateCart = function updateCart(response) {
  // rewrite response from Back to Object
  response = JSON.parse(response);

  /**
   * default view of response
   * {
   *     discount: Number,
   *     shipping: Number,
   *     // if discount very from different Products
   *     productDiscount: [
   *       [id: string]: discount: Number
   *     ],
   *     // if discount for ALL Products
   *     productDiscount: Number,
   *   }
   * **/

  const productDiscount = JSON.parse(response.productDiscount);

  // clear basket discount
  basket.discount = 0;
  // if productDiscount is bigger than zero
  if (Object.keys(productDiscount).length) {
    // filter discount for products
    for (const [key, value] of Object.entries(productDiscount)) {
      basket.cart.forEach((product) => {
        if (product.productId === key) {
          product.discount = Number(value);
        }
      });
    }
  } else {
    basket.cart.forEach((product) => {
      product.discount = Number(response.discount);
    });
  }

  // Update shipping price
  basket.shipping = basket.subtotal() > 100 ? 0 : response.shipping;
  if (basket.status) {
    amountCalc();
  }
};

/**
 * Comment out Slider with following tooltips animation
 * */
window.doubleRangeSliderTooltipsOld = function doubleRangeSliderTooltipsOld() {
  const doubleRange = document.getElementsByClassName(
    "double-range-tooltips"
  )[0];
  if (doubleRange) {
    const slider = doubleRange.querySelector("#double-range-tooltips");
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || "£";
    const step = +slider.dataset.step;
    const inputsHidden = doubleRange.querySelectorAll(
      ".double-range-hidden-input"
    );
    const startValueMin = +inputsHidden[0].value;
    const startValueMax = +inputsHidden[1].value;

    // how many percentages limit from borders 👇 is 8%
    const borderLimit = 8;

    // each step is go backward for this amount of % 👇 is 5%
    const borderDiff = 40 / borderLimit;

    noUiSlider.create(slider, {
      start: [startValueMin, startValueMax],
      connect: true,
      tooltips: true,
      margin: 10,
      step: step,
      range: {
        min: min,
        max: max,
      },
    });

    const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
    const circlesArr = slider.querySelectorAll(".noUi-origin");

    function returnTransform(element) {
      return element
        .replace(/[^0-9][^\d.]/g, "")
        .replace(")", "")
        .split(" ")
        .map((str) => {
          return Number(str);
        });
    }

    function needToMerge() {
      let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
      let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

      if (
        tooltipsArr[0].classList.contains("hidden") ||
        tooltipsArr[1].classList.contains("hidden")
      ) {
        return true;
      }

      return (
        tooltipOnePosition.left +
          tooltipOnePosition.width -
          tooltipTwoPosition.left >
        0
      );
    }

    function resetTooltips(values) {
      mergeDiff = null;
      tooltipsArr.forEach((elem, index) => {
        elem.textContent =
          unit + Math.round(values[index]).toLocaleString("en-GB");
        elem.classList.remove("hidden");
      });
    }

    let trueTooltip = false;
    let mergeDiff = null;

    // Which Handle was grabbing when slider tooltips is merged
    // : not_merged = null; first = 0; second = 1;
    let trueHandle = null;

    slider.noUiSlider.on("update", function (values, handle) {
      // translate of noUISlider -> 0% is start, 100% is end
      let translate = returnTransform(circlesArr[handle].style.transform)[0];

      // min value of double range slider
      let valueMin = returnTransform(circlesArr[0].style.transform)[0];

      // max value of double range slider
      let valueMax = returnTransform(circlesArr[1].style.transform)[0];

      // difference between min and max value of double range slider
      let difference = valueMax - valueMin;

      inputsHidden[handle].value = Math.round(values[handle]);

      // if tooltips are close to each other
      if (needToMerge()) {
        if (trueHandle === null) {
          trueHandle = handle;
        }

        if (
          !tooltipsArr[+!handle].classList.contains("hidden") &&
          !tooltipsArr[handle].classList.contains("hidden")
        ) {
          trueTooltip = handle;
          mergeDiff = difference;
          tooltipsArr[+!handle].classList.add("hidden");
        }

        if (handle) {
          // limit left merged tooltip from overflowing
          // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
          if (translate <= -100 + borderLimit * 3) {
            tooltipsArr[trueTooltip].style.transform = `translate(${
              -50 -
              difference +
              (Math.abs(translate + 100 - borderLimit * 3) * borderDiff) / 2.5
            }%,100%)`;
          } else {
            // position of tooltip in the middle of range
            tooltipsArr[trueTooltip].style.transform = `translate(${
              -50 - difference
            }%,100%)`;
          }
        } else {
          // if left tooltip is grabbed
          if (translate >= -borderLimit * 4) {
            // limit right merged tooltip from overflowing
            tooltipsArr[trueTooltip].style.transform = `translate(${
              -50 +
              difference -
              (Math.abs(translate + borderLimit * 3) * borderDiff) / 2.5
            }%,100%)`;
          } else {
            tooltipsArr[trueTooltip].style.transform = `translate(${
              -50 + difference
            }%,100%)`;
          }
        }

        tooltipsArr[trueTooltip].textContent = `${
          unit + Math.round(values[0]).toLocaleString("en-GB")
        } - ${unit + Math.round(values[1]).toLocaleString("en-GB")}`;

        if (mergeDiff - difference < 0) {
          mergeDiff = null;
          resetTooltips(values);
        }
      } else {
        // limit left solo tooltip from overflowing
        if (translate <= -100 + borderLimit) {
          tooltipsArr[0].style.transform = `translate(${
            -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
          }%,100%)`;
        } else if (translate >= -borderLimit) {
          // limit right solo tooltip from overflowing
          tooltipsArr[1].style.transform = `translate(${
            -50 - Math.abs(translate + borderLimit) * borderDiff
          }%,100%)`;
        } else {
          // position of tooltip in the middle of range
          tooltipsArr[handle].style.transform = "translate(-50%,100%)";
        }

        tooltipsArr[handle].textContent = `${
          unit + Math.round(values[handle]).toLocaleString("en-GB")
        }`;
      }
    });
  }
};
// doubleRangeSliderTooltips();

window.doubleRangeSliderTooltips = function doubleRangeSliderTooltips() {
  const doubleRange = document.getElementsByClassName(
    "double-range-default"
  )[0];
  if (doubleRange) {
    const slider = doubleRange.querySelector("#double-range-default");
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || "£";
    const step = +slider.dataset.step;
    const inputsHidden = doubleRange.querySelectorAll(
      ".double-range-hidden-input"
    );
    const inputInfo = doubleRange.querySelector(".double-range-info");
    const startValueMin = +inputsHidden[0].value;
    const startValueMax = +inputsHidden[1].value;

    noUiSlider.create(slider, {
      start: [startValueMin, startValueMax],
      connect: true,
      margin: 10,
      step: step,
      range: {
        min: min,
        max: max,
      },
    });

    slider.noUiSlider.on("update", function (values, handle) {
      inputInfo.innerHTML = `
        <div class="flex justify-between">
              <span class="noUi-tooltip">${
                unit + Math.round(values[0]).toLocaleString("en-GB")
              }</span>
              <span class="noUi-tooltip">${
                unit + Math.round(values[1]).toLocaleString("en-GB")
              }</span>
        </div>
    `;

      inputsHidden[handle].value = Math.round(values[handle]);
    });
  }
};
doubleRangeSliderTooltips();

$(document).ready(function ($) {
  if (
    navigator.userAgent.includes("Safari") &&
    !navigator.userAgent.includes("Chrome") &&
    (navigator.userAgent.includes("Version/12") ||
      (navigator.userAgent.includes("Version/13") &&
        (navigator.userAgent.includes("iPhone") ||
          navigator.userAgent.includes("iPad"))))
  ) {
    console.log("Tagify don't work here");
  } else {
    console.log("Tagify work here");

    // The DOM element you wish to replace with Tagify
    var input = document.querySelector("input#keywords_head");

    // initialize Tagify on the above input node reference
    const tagifyInput = new Tagify(input, {
      // Validate typed tag(s) by Regex. Here maximum chars length is defined as "10"
      pattern: /^.{0,10}$/,
      delimiters: ",| ",
      keepInvalidTags: false,
      editTags: {
        clicks: 1,
        keepInvalid: false,
      },
      maxTags: 4,
    });

    function updateHiddenInput() {
      const result = [];
      tagifyInput.value.forEach((tag) => {
        result.push(tag.value);
      });

      document.getElementById("keywords_head_result").value = result.join(",");
    }

    tagifyInput.on(
      "keydown",
      _.debounce((e) => {
        // load more tags from Back
        load("shop/getTags", `keyword=${tagifyInput.state.inputText}`);
      }, 150)
    );

    tagifyInput.on("add", updateHiddenInput);
    tagifyInput.on("remove", updateHiddenInput);

    window.getTagsFromBack = function getTagsFromBack(tags) {
      tagifyInput.whitelist = JSON.parse(tags);
    };
  }

  const select2Array = Array.from(
    document.getElementsByClassName("select2-element")
  );
  if (select2Array.length > 0) {
    select2Array.forEach((elem) => {
      $(elem).select2({
        minimumResultsForSearch: Infinity,
      });
    });
  }

  $(".contact-us__select").niceSelect();
  $(".search__select").niceSelect();
  $(".details-select").niceSelect();
  $(".products-search__select").niceSelect();

  if (document.querySelector(".deliver__check")) {
    const deliverCheck = document.querySelector(".deliver__check");
    const deliverContent = document.querySelector(".deliver__hidden");

    deliverCheck.addEventListener("click", () => {
      deliverContent.classList.toggle("active");
      deliverCheck.classList.toggle("active");
    });
  }
  // beefUp
  // tabs
  $(".descriptions__item").beefup({
    animation: "fade",
    openSingle: true,
    openSpeed: 500,
    closeSpeed: 500,
    onOpen: function ($el) {
      $('a[href="#' + $el.attr("id") + '"]')
        .addClass(this.openClass)
        .siblings()
        .removeClass(this.openClass);
    },
  });

  // end beefUp
  // window.productsImgSlider = function productsImgSlider() {
  //   if (document.getElementsByClassName(`products__img-slider`)[0]) {
  //     const sliders = document.querySelectorAll('.products__img-slider')
  //
  //     sliders.forEach((slider, i) => {
  //       const swiper = new Swiper(slider, {
  //         observer: true,
  //         slidesPerView: 1,
  //         loop: false,
  //         speed: 400,
  //         pagination: {
  //           clickable: true,
  //           el: ".products__pagination",
  //         },
  //         autoplay: false,
  //       });
  //
  //       slider.closest('.products__card').addEventListener('mouseenter', () => {
  //         swiper.params.autoplay = {
  //           delay: 1500,
  //           disableOnInteraction: false
  //         };
  //         swiper.autoplay.start()
  //       })
  //       slider.closest('.products__card').addEventListener('mouseleave', () => {
  //         swiper.autoplay.stop()
  //       })
  //     })
  //   }
  // }
  // productsImgSlider()

  if (document.getElementsByClassName(`first__slider`)[0]) {
    const firstSlider = document.getElementsByClassName(`first__slider`)[0];
    const firstArr = Array.from(firstSlider.querySelectorAll(".swiper-slide"));

    MetaSwiper(".first__slider", {
      observer: true,
      slidesPerView: 1,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: ".first__pagination",
      },
    });
  }

  if (document.getElementsByClassName(`first-slider__slider`)[0]) {
    const firstSlider =
      document.getElementsByClassName(`first-slider__slider`)[0];
    const firstArr = Array.from(firstSlider.querySelectorAll(".swiper-slide"));

    MetaSwiper(".first-slider__slider", {
      observer: true,
      slidesPerView: 1,
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".first-slider__next",
        prevEl: ".first-slider__prev",
      },
      pagination: {
        el: ".first-slider__pagination",
      },
    });
  }

  if (document.getElementsByClassName(`products__slider`)[0]) {
    const productsSlider =
      document.getElementsByClassName(`products__slider`)[0];
    const productsArr = Array.from(
      productsSlider.querySelectorAll(".swiper-slide")
    );
    MetaSwiper(".products__slider", {
      observer: true,
      slidesPerView: "auto",
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".products__next",
        prevEl: ".products__prev",
      },
    });
  }

  if (document.getElementsByClassName(`blog__slider`)[0]) {
    const productsSlider = document.getElementsByClassName(`blog__slider`)[0];
    const productsArr = Array.from(
      productsSlider.querySelectorAll(".swiper-slide")
    );
    MetaSwiper(".blog__slider", {
      loop: false,
      observer: true,
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        569: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
    });
  }

  if (document.getElementsByClassName(`news__slider`)[0]) {
    const newsSlider = document.getElementsByClassName(`news__slider`)[0];
    const newsArr = Array.from(newsSlider.querySelectorAll(".swiper-slide"));

    let allowTouchMove = true;
    let autoplay;

    if (newsArr.length <= 1) {
      allowTouchMove = false;
      autoplay = false;
    } else {
      autoplay = {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      };
    }

    MetaSwiper(".news__slider", {
      loop: false,
      observer: true,
      slidesPerView: "auto",
      speed: 800,
      autoplay: autoplay,
      navigation: {
        nextEl: ".news__next",
        prevEl: ".news__prev",
      },
      allowTouchMove: allowTouchMove,
    });
  }

  const thumbsProductsSliders = document.getElementsByClassName(
    `products-thumbs-slider`
  )[0];
  if (thumbsProductsSliders) {
    const thumbsSwiperBottom = new Swiper(".thumbs-slider-bottom", {
      spaceBetween: 19,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
    });
    SafariSwiper(thumbsSwiperBottom);

    const thumbsSwiperTop = new Swiper(".thumbs-slider-top", {
      spaceBetween: 20,
      thumbs: {
        swiper: thumbsSwiperBottom,
      },
    });
    SafariSwiper(thumbsSwiperTop);

    const bottomSlider = thumbsProductsSliders.querySelectorAll(
      ".thumbs-slider-bottom .swiper-slide"
    );
    bottomSlider.forEach((elem, index) => {
      elem.addEventListener("click", (e) => {
        thumbsSwiperTop.slideTo(index);
      });
    });
  }

  // sub-menu
  const subMenu = (subWrapper) => (e) => {
    const layout = subWrapper.querySelector(".header__layout");
    const panel = subWrapper.querySelector(".header__wrapper");
    const subBtn = subWrapper.querySelector(".header__menu-btn");
    const subBtnArrow = subWrapper.querySelector(
      ".header__menu-btn .header__arrow"
    );
    const burgerMenu = subWrapper.querySelector(".header__navbar-box");
    const burgerBtn = subWrapper.querySelector(".header__burger");

    if (subBtn === e.target || subBtnArrow === e.target) {
      panel.classList.toggle("active");
      burgerMenu.classList.remove("active");
      burgerBtn.classList.remove("active");
      layout.classList.add("active");
      if (
        !panel.classList.contains("active") &&
        !burgerBtn.classList.contains("active")
      ) {
        layout.classList.remove("active");
      }
    } else if (layout === e.target) {
      panel.classList.remove("active");
      layout.classList.remove("active");
    }
  };

  if (document.querySelector(".header")) {
    const subWrapper = document.querySelector(".header");
    subWrapper.addEventListener("click", subMenu(subWrapper));
  }
  // end sub-menu

  const burgerMenu = (menuSelector, burgerSelector) => {
    const menuElem = document.querySelector(menuSelector),
      layoutElem = document.querySelector(".header__layout"),
      headerElem = document.querySelector(".header__wrapper"),
      burgerElem = document.querySelector(burgerSelector);

    const removeClass = () => {
      menuElem.classList.remove("active");
      burgerElem.classList.remove("active");
      // layoutElem.classList.remove("active");
    };
    removeClass();

    menuElem.querySelectorAll("a").forEach((i) => {
      i.addEventListener("click", (e) => {
        if (menuElem.classList.contains("active")) {
          removeClass();
        }
      });
    });

    burgerElem.addEventListener("click", () => {
      if (menuElem.classList.contains("active")) {
        removeClass();
      } else {
        menuElem.classList.add("active");
        burgerElem.classList.add("active");
        headerElem.classList.remove("active");
        layoutElem.classList.add("active");
      }

      if (
        !headerElem.classList.contains("active") &&
        !burgerElem.classList.contains("active")
      ) {
        layoutElem.classList.remove("active");
      }
    });

    layoutElem.addEventListener("click", () => {
      removeClass();
      layoutElem.classList.remove("active");
    });
  };

  burgerMenu(".header__navbar-box", ".header__burger");

  // accardion
  const accardionToggle = (subBtns) => (e) => {
    subBtns.forEach((btn) => {
      const subMenu = btn.nextElementSibling;

      if (btn === e.target) {
        e.currentTarget.classList.toggle("active");
        subMenu.classList.toggle("active");
      } else {
        btn.classList.remove("active");
        subMenu.classList.remove("active");
      }
    });
  };

  const subHeader = document.querySelectorAll(".header__title");
  const subFooter = document.querySelectorAll(".footer__name");

  subHeader.forEach((links) => {
    links.addEventListener("click", accardionToggle(subHeader));
  });
  subFooter.forEach((links) => {
    links.addEventListener("click", accardionToggle(subFooter));
  });
  // end accardion

  // upload btn
  function nameCut(wrapper) {
    const upload = wrapper.querySelector("input");
    const uploadLabel = wrapper.querySelector("span");
    if (upload && uploadLabel) {
      upload.addEventListener("change", function () {
        let dots;
        const arr = this.files[0].name.split(".");
        arr[0].length > 4 ? (dots = "...") : (dots = ".");
        const name = arr[0].substring(0, 6) + dots + arr[1];
        uploadLabel.innerHTML = name;
      });
    }
  }
  if (document.querySelector(".contact-us__upload-box")) {
    const contactUsUpload = document.querySelector(".contact-us__upload-box");
    nameCut(contactUsUpload);
  }
  // end upload btn

  // Shop

  const accordionFiltersProducts = () => {
    const btn = document.querySelectorAll(".accordion-trigger"),
      item = document.querySelectorAll(".accordion-content");

    if (document.querySelector(".products-sidebar__accordion")) {
      btn.forEach((elem, index) => {
        elem.addEventListener("click", (event) => {
          let target = event.target;
          target = target.closest(".accordion-trigger");
          if (target.closest(".accordion-trigger.active")) {
            elem.classList.remove("active");
            item[index].classList.remove("active");
          } else {
            elem.classList.toggle("active");
            item[index].classList.toggle("active");
          }
        });
      });
    }
  };
  // accordionFiltersProducts();

  // OmRangeSlider.init();

  const doubleRangeDisplay = () => {
    const doubleRange = document.getElementsByClassName("double-range")[1];
    if (doubleRange) {
      const displayBlock = document.querySelector(".om-sliderrange-display"),
        displaySpanArr = document.querySelectorAll(
          ".om-sliderrange-display span"
        ),
        input = document.getElementById("inputPieces");

      const maxValue = +input.getAttribute("max");

      const buttonStart = document.querySelector(
          ".om-sliderrange-button-start"
        ),
        buttonEnd = document.querySelector(".om-sliderrange-button-end");

      displaySpanArr[0].style.left = buttonStart.offsetLeft + "px";
      displaySpanArr[2].style.left = buttonEnd.offsetLeft + "px";

      doubleRange.addEventListener("mousemove", (e) => {
        displaySpanArr[0].style.left = buttonStart.offsetLeft + "px";
        displaySpanArr[2].style.left = buttonEnd.offsetLeft + "px";
      });

      if (window.innerWidth <= 1025) {
        doubleRange.addEventListener("touchmove", (e) => {
          displaySpanArr[0].style.left = buttonStart.offsetLeft + "px";
          displaySpanArr[2].style.left = buttonEnd.offsetLeft + "px";
        });
      }
    }
  };
  // doubleRangeDisplay();

  // Products sidebar

  const sidebarProducts = () => {
    const productsSidebar =
      document.getElementsByClassName("products-sidebar")[0];
    if (productsSidebar && window.innerWidth <= 769) {
      var filtersBtnOpen = document.getElementById("open-filters-btn");
      var filtersBtnClose = document.getElementById("close-filters-btn");

      const confirmBtn = document.querySelector(
        ".show-search-block .btn--form"
      );

      function openSidebar() {
        productsSidebar.classList.add(`active`);
      }
      function closeSidebar() {
        productsSidebar.classList.remove(`active`);
      }

      filtersBtnOpen.addEventListener("click", openSidebar);

      [filtersBtnClose, confirmBtn].forEach((item) => {
        item.addEventListener("click", closeSidebar);
      });

      productsSidebar.addEventListener("click", (e) => {
        if (!e.target.closest(".products-sidebar__container")) {
          productsSidebar.classList.remove(`active`);
        }
      });
    }
  };
  sidebarProducts();

  const checkoutSection = document.getElementsByClassName("checkout")[0];
  if (checkoutSection) {
    const cardNumberInput = document.getElementById("cardNumber");
    const expiryCardInput = document.getElementById("expiry-card");
    const banksLogosBlock = document.getElementById("credit-card-logos");

    const banksArr = banksLogosBlock.querySelectorAll(".credit-card__logo");

    let today = new Date();
    let nextMonthDate = null;

    if (today.getMonth() == 11) {
      nextMonthDate = new Date(today.getFullYear() + 1, 0, 1);
    } else {
      nextMonthDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    }

    let dd = String(nextMonthDate.getDate()).padStart(2, "0");
    let mm = String(nextMonthDate.getMonth() + 1).padStart(2, "0");
    let yyyy = nextMonthDate.getFullYear().toString().slice(-2);

    let expiryCardCleave = new Cleave(expiryCardInput, {
      date: true,
      datePattern: ["m", "y"],
      dateMin: `${yyyy}-${mm}-${dd}`,
    });

    let creditCardNumberCleave = new Cleave(cardNumberInput, {
      creditCard: true,

      onCreditCardTypeChanged: function (type) {
        let count = 0;
        banksArr.forEach((elem) => {
          if (elem.getAttribute("data-bank-name") === type) {
            banksLogosBlock.classList.add("known-bank");
            elem.classList.add("active");
            count++;
          } else {
            elem.classList.remove("active");
          }
        });

        if (count === 0) {
          banksLogosBlock.classList.remove("known-bank");
        }
      },
    });
  }
});

window.initMap = function initMap(google, icon, json, defaultJson, zoom) {
  const mapsMarkersArr = [];
  map = new google.maps.Map(document.getElementById("map"), {
    center: new google.maps.LatLng(
      json.length > 0 && json[0].lat ? json[0].lat : 51.50853,
      json.length > 0 && json[0].lng ? json[0].lng : -0.12574
    ),
    zoom: Number(zoom),
    maxZoom: 15,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_LEFT,
    },
    mapTypeControl: false,
  });

  const card = document.getElementById("pac-card");
  const input = document.getElementById("postcode");
  const options = {
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
    types: ["establishment"],
  };

  // BUG
  // map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);

  const autocomplete = new google.maps.places.Autocomplete(input, options);
  autocomplete.bindTo("bounds", map);

  const infowindow = new google.maps.InfoWindow();
  const infowindowContent = document.getElementById("infowindow-content");
  infowindow.setContent(infowindowContent);

  if (defaultJson) {
    var latLngDefault = new google.maps.LatLng(
      defaultJson.lat,
      defaultJson.lng
    );

    marker = new google.maps.Marker({
      map,
      position: latLngDefault,
      icon: icon,
      title: defaultJson.title,
    });

    mapsMarkersArr.push(marker);
  }

  function bindInfoWindow(marker, map, infowindow, strDescription) {
    google.maps.event.addListener(marker, "click", function () {
      infowindow.setContent(strDescription);
      infowindow.open(map, marker);
    });
  }

  if (json.length) {
    $.each(json, function (key, data) {
      var latLng = new google.maps.LatLng(data.lat, data.lng);
      marker = new google.maps.Marker({
        position: latLng,
        map: map,
        // icon: icon,
        title: data.title,
      });
      bindInfoWindow(marker, map, infowindow, data.title);

      mapsMarkersArr.push(marker);
    });
  }

  autocomplete.addListener("place_changed", () => {
    infowindow.close();

    const place = autocomplete.getPlace();

    document.getElementById("lng").value = place.geometry.location.lng();
    document.getElementById("lat").value = place.geometry.location.lat();
  });

  return mapsMarkersArr;
};

const inputValid = document.querySelectorAll("input");

if (inputValid.length) {
  inputValid.forEach((input) => {
    input.addEventListener("focus", function () {
      if (input.classList.contains("invalid")) {
        this.classList.remove("invalid");
      }
    });
  });
}

const countryTooltip = document.querySelector(".tooltip-country");

if (!!countryTooltip) {
  const countryText = document.querySelector(".tooltip-country-wrapper");

  countryTooltip.addEventListener("mouseenter", () => {
    countryText.classList.add("active");
  });

  countryTooltip.addEventListener("mouseleave", () => {
    countryText.classList.remove("active");
  });
}

if (innerWidth < 901 && document.querySelector(".header__link-box")) {
  const colsHead = document.querySelectorAll(".header__link-box");
  colsHead.forEach((col) => {
    new SimpleBar(col);
  });
}
